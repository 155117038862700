import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { StoreModule } from "@ngrx/store";
import { credentialsReducer } from './transvmanager/stores/credentials/credentials.reducer';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { fuelsReducer, currentGasolineReducer, currentDieselReducer, fuelsByDriverReducer, previousFuelBalanceReducer } from './transvmanager/stores/fuels/fuels.reducer';
import { loadersReducer } from './transvmanager/stores/loaders/loaders.reducer';
import { menuBarReducer } from './transvmanager/stores/menubar/menubar.reducer';
import { discountsReducer } from './transvmanager/stores/discounts/discounts.reducer';
import { dateReducer } from './transvmanager/stores/date/date.reducer';
import { rentalsByDriverReducer, unpaidRentsReducer } from './transvmanager/stores/rentals/rentals.reducer';
import { totalDebtsByEmployeeReducer } from './transvmanager/stores/debts/debts.reducer';
import { PrintLayoutComponent } from './transvmanager/components/print-layout/print-layout.component';
import { InvoiceComponent } from './transvmanager/components/invoice/invoice.component';

const routes: Routes = [
  
  {
    path: "transvmanager",
    loadChildren: "./transvmanager/transvmanager.module#TransvmanagerModule"
  },
  {
    path: "transvlogin",
    loadChildren: "./transvlogin/transvlogin.module#TransvLoginModule"
  },
  { path: "**", redirectTo: "transvlogin" }
];
@NgModule({
  declarations: [
    AppComponent,
    PrintLayoutComponent,
    InvoiceComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routes, { useHash: true }),
    HttpClientModule,
    StoreModule.forRoot({
      credentials: credentialsReducer,
      fuels: fuelsReducer,
      fuelsByDriver: fuelsByDriverReducer,
      rentalsByDriver: rentalsByDriverReducer,
      currentGasoline: currentGasolineReducer,
      currentDiesel: currentDieselReducer,
      discounts: discountsReducer,
      loader: loadersReducer,
      menuBar: menuBarReducer,
      date: dateReducer,
      unpaidRents: unpaidRentsReducer,
      previousFuelBalance: previousFuelBalanceReducer,
      totalDebtsByEmployee: totalDebtsByEmployeeReducer
    }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
